//menu
//toogle css in header__wrapper  z-index
function toggleCss(wrapper, menu) {
	if (menu.className === 'rbn-header__menu rbn-header__menu_active') {
		wrapper.setAttribute('style', 'z-index: 20');
	} else {
		wrapper.setAttribute('style', 'z-index: -1');
	}
}
const menuBtn = document.querySelector('.rbn-header__language>span');
const menu  = document.querySelector('.rbn-header__menu');
const closeIcon = document.querySelector('.rbn-header__menu-close');
const wrapper = document.querySelector('.rbn-header__wrapper');
//open menu
menuBtn.addEventListener('click', () => {
	menu.classList.toggle('rbn-header__menu_active');
	wrapper.classList.toggle('rbn-header__wrapper_active');
	toggleCss(wrapper, menu);
});
//closing menu by icon close
closeIcon.addEventListener('click', () => {
	menu.classList.toggle('rbn-header__menu_active');
	wrapper.classList.toggle('rbn-header__wrapper_active');
	toggleCss(wrapper, menu);
});
//closing menu by esc
addEventListener('keydown', (e) => {
	if (menu.className === 'rbn-header__menu rbn-header__menu_active' && e.keyCode === 27) {
		menu.classList.remove('rbn-header__menu_active');
		wrapper.classList.remove('rbn-header__wrapper_active');
		toggleCss(wrapper, menu);
	}
});

//scroll STICKY MENU
//let scrollpos = window.scrollY;
//const header = document.querySelector('.rbn-header');
//const scrollChange = 1;
//const addClassOnScroll = () => header.classList.add('rbn-header_active');
//const removeClassOnScroll = () => header.classList.remove('rbn-header_active');

//window.addEventListener('scroll', () => {
//	scrollpos = window.scrollY;
//	if (scrollpos >= scrollChange) {
//		addClassOnScroll();
//	} else {
//		removeClassOnScroll();
//	}
//});

let scrollpos = window.scrollY;
const arrowTop = document.querySelector('.rbn-arrowTop__svg');
const addClassOnScroll = () => arrowTop.classList.add('rbn-arrowTop__svg_active');
const removeClassOnScroll = () => arrowTop.classList.remove('rbn-arrowTop__svg_active');
const scrollChange = 500;
window.addEventListener('scroll', () => {
	scrollpos = window.scrollY;
	if (scrollpos >= scrollChange) {
		addClassOnScroll();
	} else {
		removeClassOnScroll();
	}
});
arrowTop.addEventListener('click',  () => {
	//window.scrollTo(0, 0);
	window.scrollTo({ top: 0, behavior: 'smooth' });
});
