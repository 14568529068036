import Zooming from 'zooming';


document.addEventListener('DOMContentLoaded', () => {
	const  imagesZoom = document.querySelectorAll('.rbn-imgZoom img');
	if (imagesZoom) {
		imagesZoom.forEach(img => {
			img.classList.add('rbn-zoomIn');
		});
	}
	const zooming = new Zooming({
		bgColor: 'rgba(255, 255, 255, 0.9);'
	});
	zooming.listen('.rbn-zoomIn');
});
