import Macy from 'macy-mod'; // bildsplate

if (document.querySelector('.rbn-gallery__gridImg')) {
	const macyArray = document.querySelectorAll('.rbn-gallery__gridImg');
	macyArray.forEach((grid) => {
		Macy({
			container: grid,
			columns: 2,
			margin: {
				x: 32,
				y: 40
			}
		});
	});
}
if (document.querySelector('.rbn-bildspalte')) {
	const bildSpaltes = document.querySelectorAll('.rbn-bildspalte');
	bildSpaltes.forEach((bildSpalte) => {
		Macy({
			container: bildSpalte,
			trueOrder: false,
			columns: 3,
			breakAt: {
				1024: 2,
				768: 1,
			},
			margin: {
				x: 40,
				y: 40
			},
		});
	});
}
