//akordion
if(document.querySelector('.rbn-akkordeon')) {
    const akkordeons = document.querySelectorAll('.rbn-akkordeon');
    for (let i = 0; i < akkordeons.length; i++) {
        akkordeons[i].querySelector('.rbn-akkordeon__head').addEventListener('click', () => {
            akkordeons[i].querySelector('.rbn-akkordeon__btn').classList.toggle('rbn-akkordeon__btn_active');
            const panel  = akkordeons[i].querySelector('.rbn-akkordeon__body');
            panel.classList.toggle('rbn-akkordeon__body_active');
        });
    }
}
