import domMixin from '../dom/dom-mixin';
import offsetTop from '../utils/offset-top';


class HashScroller extends domMixin() {

    constructor({events, duration = 2}) {
		super();
		this.events = events;
		this.duration = duration;
		this.events.on(document, 'history:hashchange', this.onHashChange.bind(this));
    }


	async onHashChange(event) {
		const hash = event.detail.hash;
		console.log('hash change', hash);
		if (hash !== null && hash !== undefined) {
			let element;
			let position;
			if (hash.length === 0) {
				element = true;
				position = 0;
			} else {
				element = document.querySelector('#' + hash);
				if (!element) {
					element = document.querySelector(this.dataSelector('hash', hash));
				}
			}
			console.log('hash change element', hash, element);
			if (element) {
				const gsap = (await import('../libs/gsap')).gsap;
				console.log('gsap', gsap);
				requestAnimationFrame(() => {
					if (element !== true) {
						position = offsetTop(element);
						console.log('element position for', hash, position);
					}
					gsap.to(window, {duration: this.duration, scrollTo: {y: position, autoKill: false}});
				});
			}
		}
	}

}


export default HashScroller;
