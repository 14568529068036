if (document.querySelector('.rbn-bildspalte')) {
	const bildSpaltes = document.querySelectorAll('.rbn-bildspalte');
	bildSpaltes.forEach((bildSpalte) => {
		const items = bildSpalte.querySelectorAll('.rbn-bildspalte__item');
		items.forEach((item) => {
			const title = item.querySelector('.rbn-bildspalte__title');
			const content  = item.querySelector('.rbn-bildspalte__content');
			const background = item.querySelector('.rbn-bildspalte__background');
			const text =  item.querySelector('.rbn-bildspalte__text');
			const link =  item.querySelector('.rbn-bildspalte__link');
			content.addEventListener('mouseover', () => {
				background.classList.add('rbn-bildspalte__background_active');
				if (text) {text.classList.add('rbn-bildspalte__text_active');}
				if (title) {title.classList.add('rbn-bildspalte__title_active');}
				if (link) {link.classList.add('rbn-bildspalte__link_active');}
			});
			content.addEventListener('mouseout', () => {
				background.classList.remove('rbn-bildspalte__background_active');
				if(text) {text.classList.remove('rbn-bildspalte__text_active');}
				if(title) {title.classList.remove('rbn-bildspalte__title_active');}
				if(link) {link.classList.remove('rbn-bildspalte__link_active');}
			});
		});

	});
  }
