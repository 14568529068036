import './rbn/macy'; //gallery
import './rbn/menu'; //navabr
import './rbn/bildsplace';
import './rbn/akordion';
import './rbn/events';
import './rbn/medium-zoom';
import Container from './common/di/container';
import config from './app/config/config';
//todo
export default () => {
	const container = new Container();
	container.setValue('dom/dataAttrPrefix', 'rbn-');
	container.setValue('dom/classPrefix', 'rbn-js-');
	container.setValue('dom/baseClassPrefix', 'rbn-');
	config(container);
	const app = container.get('app');
	app.init().then(() => app.execute());
};
