import keymaster from 'keymaster';
import PageComponent from '../../common/component/page-component';

class Grid extends PageComponent {
	constructor({
			root,
			element,
			activeClass = 'active'
		}) {
		super({root: root, element: element});

		this.activeClass = activeClass;
	}

	prepare() {
		keymaster('g', this.onKey.bind(this));
  }

	onKey() {
		this.classList(this.element).toggle(this.activeClass);
	}
}

export default Grid;
